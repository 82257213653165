import { useEffect, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { ACTIVE } from "../../utils/constants";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  BasicSelect,
  BasicTextField,
} from "@datwyler/shared-components";

const inputLabelProps = {
  sx: {
    fontSize: "14px",
    letterSpacing: "0.01px",
    top: "3px",
  },
};

const inputProps = {
  sx: {
    height: "44px",
  },
};

const TenantDialog = (props) => {
  const {
    isOpen = false,
    closeDialog,
    addTenant,
    updateTenant,
    selectedTenant,
  } = props;
  const intl = useIntl();
  const form = useForm();
  const { colors }: any = useContext(DarkModeContext);
  useEffect(() => {
    form.reset({
      name: selectedTenant?.name || null,
      managed: selectedTenant?.managed ? "Y" : "N",
    });
  }, [selectedTenant]);

  const inputRules = {
    required: {
      type: "required",
      value: true,
      message: intl.formatMessage({ id: "validation_required" }),
    },
  };

  const handleSave = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const submitValues = form.getValues();
      const payload = transformBeforeSend(submitValues);
      if (selectedTenant) {
        updateTenant({
          variables: { input: payload },
        });
      } else {
        addTenant({
          variables: { input: payload },
        });
      }
    }
  };

  const transformBeforeSend = (submitValues: any) => {
    const payload: any = {};
    payload.name = submitValues.name;
    if (selectedTenant) {
      payload.status = selectedTenant?.status ? selectedTenant.status : ACTIVE;
      payload.dashboard = selectedTenant.dashboard;
      payload.id = selectedTenant.id;
      payload.managed = submitValues.managed === "Y" ? true : false;
    }
    return payload;
  };

  const handleClose = () => {
    closeDialog();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {selectedTenant
            ? intl.formatMessage({ id: "update_tenant" })
            : intl.formatMessage({ id: "add_tenant" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent sx={{ overflowX: "hidden", paddingTop: "8px" }}>
        <Box sx={{ width: "376px" }}>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "enter_tenant_name" })}
              type={"text"}
              form={form}
              formItemLabel={"name"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              rules={inputRules}
            />
          </Box>
          {selectedTenant && (
            <Box sx={{ marginTop: "16px" }}>
              <BasicSelect
                form={form}
                label={"Manage Tenant"}
                formItemLabel={"managed"}
                defaultValue={selectedTenant?.managed ? "Y" : "N"}
                menuItems={[
                  { priority: 0, value: "Y", label: "Yes" },
                  { priority: 1, value: "N", label: "No" },
                ]}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleSave}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TenantDialog;
