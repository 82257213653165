import { useState, MouseEvent, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { useIntl } from "react-intl";
import {
  BasicSelectWithCheckBox,
  BasicButton,
  FilterIcon,
  DarkModeContext,
  CalendarIcon,
  BasicDateRangePickerWithoutForm,
  getEpochTime,
} from "@datwyler/shared-components";
import { ACTIVE, DEACTIVATED } from "../../utils/constants";

const filterContainerStyle = {
  display: "inline-flex",
  height: "44px",
  marginLeft: "24px",
};

const LicenseFilters = (props) => {
  const { defaultFilters, setFilters, subTenants } = props;
  const [tenantOptions, setTenantOptions] = useState([]);
  const [interimFilters, setInterimFilters] = useState(defaultFilters);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (subTenants) {
      const tenantOptions = subTenants.map((subTenant, index) => {
        return {
          priority: index,
          label: subTenant.name,
          value: subTenant.id,
        };
      });

      setTenantOptions(tenantOptions);
    }
  }, [subTenants]);

  const open = Boolean(anchorEl);

  const statusOptions = [
    {
      priority: 1,
      label: intl.formatMessage({ id: ACTIVE.toLocaleLowerCase() }),
      value: ACTIVE,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: DEACTIVATED.toLocaleLowerCase() }),
      value: DEACTIVATED,
    },
  ];

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeExpiryDate = (startDate, endDate) => {
    setInterimFilters({
      ...interimFilters,
      startDate: getEpochTime(startDate),
      endDate: getEpochTime(endDate),
    });
  };

  const handleChangeTenant = (tenants: Array<string>) => {
    setInterimFilters({ ...interimFilters, tenants: tenants });
  };

  const handleChangeStatus = (statuses: Array<string>) => {
    setInterimFilters({ ...interimFilters, statuses: statuses });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setInterimFilters(defaultFilters);
  };

  return (
    <Box>
      <Box sx={{ paddingTop: "16px", display: "flex", alignItems: "center" }}>
        <Box sx={filterContainerStyle}>
          <BasicButton
            variant="outlined"
            onClick={handleClick}
            sx={{ border: "1px solid " + colors.blueGray300 }}
          >
            <FilterIcon fill={colors.iconColor} />
            <Typography
              sx={{
                fontFamily: "NotoSans-Medium",
                fontSize: "14px",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "20px",
                marginLeft: "10px",
              }}
            >
              {intl.formatMessage({ id: "filters" })}
            </Typography>
          </BasicButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              marginTop: "10px",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
                borderRadius: "8px",
                backgroundColor: colors.selectDropDownBg,
              },
            }}
          >
            <Box sx={{ padding: "16px" }}>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicDateRangePickerWithoutForm
                  handleOnChange={handleChangeExpiryDate}
                  label={intl.formatMessage({ id: "expiry_date_btw" })}
                  defaultStartDate={interimFilters.startDate}
                  defaultEndDate={interimFilters.endDate}
                  InputProps={{
                    sx: {
                      width: "236px",
                      height: "44px",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        component="div"
                        style={{ paddingLeft: "-14px" }}
                        disablePointerEvents
                      >
                        <CalendarIcon fill={colors.iconColor} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeTenant}
                  values={interimFilters.tenants}
                  label={intl.formatMessage({ id: "tenant" })}
                  menuItems={tenantOptions}
                />
              </Box>
              <Box sx={{ width: "230px", marginTop: "16px" }}>
                <BasicSelectWithCheckBox
                  onChange={handleChangeStatus}
                  values={interimFilters.statuses}
                  label={intl.formatMessage({ id: "status" })}
                  menuItems={statusOptions}
                />
              </Box>
              <Box
                sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}
              >
                <BasicButton
                  variant={"outlined"}
                  onClick={resetFilters}
                  sx={{
                    padding: "12px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "NotoSans-Regular",
                      fontSize: "14px",
                      letterSpacing: 0,
                      lineHeight: "20px",
                    }}
                  >
                    {intl.formatMessage({ id: "reset" })}
                  </Typography>
                </BasicButton>
                <BasicButton
                  variant={"contained"}
                  onClick={applyFilters}
                  sx={{ marginLeft: "20px", padding: "12px 16px" }}
                >
                  {intl.formatMessage({ id: "apply" })}
                </BasicButton>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default LicenseFilters;
