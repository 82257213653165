import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { ACTIVE, DEACTIVATED } from "../../utils/constants";
import { useIntl } from "react-intl";
import {
  BasicDataTable,
  BasicPagination,
  DarkModeContext,
  getRequiredDateFormat,
} from "@datwyler/shared-components";

const headers = [
  "tenant_name",
  "status",
  "last_updated_time",
  "last_updated_by",
  "actions",
];

const columnConfigs = {
  checkbox: {
    colspan: 1,
    isCentralized: false,
  },
  tenant_name: {
    colspan: 4,
    isCentralized: false,
  },
  status: {
    colspan: 2,
    isCentralized: false,
  },
  last_updated_time: {
    colspan: 2,
    isCentralized: false,
  },
  last_updated_by: {
    colspan: 2,
    isCentralized: false,
  },
  actions: {
    colspan: 1,
    isCentralized: true,
  },
};

const TenantsTable = (props) => {
  const {
    tenantsData,
    setisTenantDialogOpen,
    setIsDeleteTenantDialogOpen,
    setSelectedTenant,
    updateTenant,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const intl = useIntl();
  const [tableData, setTableData] = useState([[]]);

  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (tenantsData) {
      setTableData(getData());
    }
  }, [tenantsData]);

  useEffect(() => {
    setTableData(getData());
  }, [page]);

  useEffect(() => {
    setTableData(getData());
  }, [rowsPerPage]);

  const getData = () => {
    const data = [];

    if (tenantsData) {
      const temp = JSON.parse(JSON.stringify(tenantsData));

      temp.forEach((tenant) => {
        data.push({
          isChecked: false,
          tenant_name: tenant.name,
          status: intl.formatMessage({ id: tenant.status?.toLowerCase() }),
          last_updated_time: getRequiredDateFormat(
            tenant.updatedOn,
            "DD/MM/YY HH:mm:ss"
          ),
          last_updated_by:
            tenant.updatedBy?.firstName + " " + tenant.updatedBy?.lastName,
          additionalInfo: tenant,
          moreButtonMenuItems: getMoreButtonMenu(tenant),
        });
      });
    }

    return data;
  };

  const getMoreButtonMenu = (tenant) => {
    return [
      {
        label: intl.formatMessage({ id: "update_tenant" }),
        value: null,
        onClick: () => handleOnClickUpdate(tenant),
      },
      {
        label:
          tenant?.status === DEACTIVATED
            ? intl.formatMessage({ id: "reactivate" })
            : intl.formatMessage({ id: "deactivate" }),
        value: null,
        menuItemFontStyle: {
          color:
            tenant?.status === DEACTIVATED
              ? colors.dataTableHeaderFont
              : colors.red800,
          fontFamily: "NotoSans-Regular",
          fontSize: "14px",
          letterSpacing: 0,
          lineHeight: "20px",
          "&:hover": {
            color:
              tenant?.status === DEACTIVATED
                ? colors.dataTableHeaderFont
                : colors.red800,
          },
        },
        onClick: () =>
          handleUpdateStatus(
            tenant,
            tenant?.status === DEACTIVATED ? ACTIVE : DEACTIVATED
          ),
      },
    ];
  };

  const handleOnClickUpdate = (tenant) => {
    setSelectedTenant(tenant);
    setisTenantDialogOpen(true);
  };

  const handleUpdateStatus = (tenant, status) => {
    if (status === DEACTIVATED) {
      setSelectedTenant(tenant);
      setIsDeleteTenantDialogOpen(true);
    } else {
      const newValues = {
        id: tenant.id,
        name: tenant.name,
        dashboard: tenant.dashboard,
        status: status,
      };

      updateTenant({
        variables: { input: newValues },
      });
    }
  };

  const handleOnCheck = (additionalInfo) => {
    const temp: any = [...tableData];
    temp.map((row) => {
      if (row.additionalInfo.id === additionalInfo.id) {
        row.isChecked = !row.isChecked;
      }
    });
    setTableData(temp);
  };

  const handleCheckAll = (e) => {
    const temp = [...tableData];
    temp.map((row: any) => {
      row.isChecked = e.target.checked;
    });

    setTableData(temp);
  };

  const handleClickRow = (additionalInfo) => {
    handleOnCheck(additionalInfo);
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        handleCheckAll={handleCheckAll}
        handleOnCheck={handleOnCheck}
        handleClickRow={handleClickRow}
        columnConfigs={columnConfigs}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default TenantsTable;
