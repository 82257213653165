import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import {
  DarkModeContext,
  CloseIcon24p,
  MultiEmailInput,
  BasicButton,
} from "@datwyler/shared-components";

const SupporMailManager = ({
  open,
  onCloseHandler,
  supportMailAddresses,
  setSupportMailAddresses,
  onSaveHandler,
}) => {
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={onCloseHandler}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {intl.formatMessage({ id: "manage_support_email_btn" })}
        </Typography>
        <IconButton
          onClick={onCloseHandler}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <MultiEmailInput
            onChange={setSupportMailAddresses}
            value={supportMailAddresses}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={onCloseHandler} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
            onClick={onSaveHandler}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SupporMailManager;
