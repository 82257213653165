import React, { useState } from "react";
import App from "./App";
import {
  GraphQLApiProvider,
  SnackBarProvider,
  useLocale,
  TranslationProvider,
  CommonStyles,
  DarkModeProvider,
  ApiProvider,
  UserProvider,
} from "@datwyler/shared-components";
import { BrowserRouter } from "react-router-dom";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <GraphQLApiProvider uri={props.GRAPHQL_URL}>
        <BrowserRouter>
          <TranslationProvider locale={locale}>
            <DarkModeProvider>
              <SnackBarProvider>
                <CommonStyles>
                  <ApiProvider uri={props.SECURED_GATEWAY_URL}>
                    <UserProvider>
                      <App />
                    </UserProvider>
                  </ApiProvider>
                </CommonStyles>
              </SnackBarProvider>
            </DarkModeProvider>
          </TranslationProvider>
        </BrowserRouter>
      </GraphQLApiProvider>
    </React.StrictMode>
  );
}
