import { Route, Routes } from "react-router-dom";
import TenantManagement from "./containers/TenantManagement";
import License from "./containers/License";
import { withPermission } from "@datwyler/shared-components";

const ProtectedTenant = withPermission("tenant")(TenantManagement);
const ProtectedLicense = withPermission("license")(License);
const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/tenant" element={<ProtectedTenant />} />
      <Route path="/tenant/license" element={<ProtectedLicense />} />
    </Routes>
  );
};

export default Router;
