import { useContext } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { BasicCard, DarkModeContext } from "@datwyler/shared-components";

interface BasicInfoCardProps {
  title: string;
  containerStyle?: Record<string, string>;
  info: {
    label: string;
    value: string;
  }[];
  columnWidth?: string;
}

const BasicInfoCard = (props: BasicInfoCardProps) => {
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const {
    title,
    info,
    containerStyle = { marginTop: "16px" },
    columnWidth = "25%",
  } = props;

  const labelStyle = {
    color: colors.subTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.13px",
    lineHeight: "20px",
  };

  const valueStyle = {
    color: colors.fontTitle,
    fontFamily: "NotoSans-Regular",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    marginTop: "5px",
  };

  const titleStyle = {
    color: colors.fontTitle2,
    fontFamily: "NotoSans-SemiBold",
    fontSize: "18px",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "20px",
  };

  return (
    <Box sx={containerStyle}>
      <BasicCard>
        <Typography sx={titleStyle}>
          {intl.formatMessage({ id: title })}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(auto-fill, ${columnWidth})`,
            gridRowGap: "16px",
            marginTop: "12px",
          }}
        >
          {info.map(({ label, value }, index) => {
            return (
              <Box key={index}>
                <Box>
                  <Typography sx={labelStyle}>
                    {intl.formatMessage({ id: label })}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={valueStyle}>{value}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </BasicCard>
    </Box>
  );
};

export default BasicInfoCard;
