import { useState, useMemo, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import { ACTIVE } from "../../utils/constants";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
  BasicSelectWithCheckMark,
  CalendarIcon,
  BasicDateRangePickerWithoutForm,
  BasicTextField,
  getEpochTime,
  useSnackbar,
} from "@datwyler/shared-components";

const inputLabelProps = {
  sx: {
    fontSize: "14px",
    letterSpacing: "0.01px",
    top: "3px",
  },
};

const inputProps = {
  sx: {
    height: "44px",
  },
};

const SelectDisplayProps = {
  paddingTop: "13px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  height: "19px",
};

const inputLabelSx = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "24px",
  fontWeight: 400,
};

const menuPaperSx = { width: "376px" };

const LicenseDialog = (props) => {
  const {
    isOpen = false,
    closeDialog,
    selectedLicense,
    addLicense,
    updateLicense,
    tenant,
    subTenants,
    licenseData,
  } = props;

  const [tenantOptions, setTenantOptions] = useState([]);
  const [dateErrors, setDateErrors] = useState(null);
  const intl = useIntl();
  const form = useForm();
  const { colors }: any = useContext(DarkModeContext);
  const { enqueueSnackbar } = useSnackbar();

  const startDate = form.watch("startDate");
  const endDate = form.watch("endDate");

  useEffect(() => {
    form.reset({
      startDate: selectedLicense?.startDate || null,
      endDate: selectedLicense?.endDate || null,
      users: selectedLicense?.users?.total || 0,
      sites: selectedLicense?.sites?.total || 0,
      devices: selectedLicense?.devices?.total || 0,
    });
  }, [selectedLicense]);

  useMemo(() => {
    if (tenant) {
      const tenantOptions = [];

      tenantOptions.push({
        priority: 0,
        label: tenant.name,
        value: tenant.id,
      });

      if (subTenants) {
        subTenants.forEach((subTenant, index) => {
          if (subTenant.status === ACTIVE) {
            tenantOptions.push({
              priority: index + 1,
              label: subTenant.name,
              value: subTenant.id,
            });
          }
        });
      }

      setTenantOptions(tenantOptions);
    }
  }, [tenant, subTenants]);

  const inputRules = {
    required: {
      type: "required",
      value: true,
      message: intl.formatMessage({ id: "validation_required" }),
    },
  };

  const isLicenseExists = (submitValues) => {
    return licenseData.some(
      (license) => license.tenant.id === submitValues.tenantId
    );
  };

  const handleSave = async () => {
    const isValid = await form.trigger();
    const submitValues = form.getValues();

    if (isLicenseExists(submitValues)) {
      enqueueSnackbar(intl.formatMessage({ id: "license_already_exist" }), {
        variant: "error",
      });

      return;
    }

    const isDateValid = dateValidation(submitValues);

    if (isValid && isDateValid) {
      transformBeforeSend(submitValues);
      if (selectedLicense) {
        // Update
        updateLicense({
          variables: { input: submitValues },
        });
      } else {
        addLicense({
          variables: { input: submitValues },
        });
      }
    }
  };

  const dateValidation = (submitValues) => {
    if (!submitValues.startDate || !submitValues.endDate) {
      setDateErrors(inputRules.required);
      return false;
    } else {
      setDateErrors(null);
    }

    return true;
  };

  const transformBeforeSend = (submitValues: any) => {
    if (selectedLicense) submitValues.id = selectedLicense.id;
    else submitValues.tenant = { id: submitValues.tenantId };
    submitValues.sites = { total: parseInt(submitValues.sites) };
    submitValues.users = { total: parseInt(submitValues.users) };
    submitValues.devices = { total: parseInt(submitValues.devices) };
    submitValues.status = ACTIVE;
    delete submitValues.tenantId;
  };

  const handleChangeLicenseDate = (startDate, endDate) => {
    form.setValue("startDate", getEpochTime(startDate));
    form.setValue("endDate", getEpochTime(endDate));
  };

  const handleClose = () => {
    form.reset({
      startDate: null,
      endDate: null,
      users: 0,
      sites: 0,
      tenantId: null,
    });
    setDateErrors(null);
    closeDialog();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "12px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {selectedLicense
            ? selectedLicense.id
            : intl.formatMessage({ id: "add_new_license" })}
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p fill={colors.iconColor} />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent sx={{ overflowX: "hidden" }}>
        <Box sx={{ width: "376px" }}>
          <Box>
            <BasicDateRangePickerWithoutForm
              handleOnChange={handleChangeLicenseDate}
              errors={dateErrors}
              defaultStartDate={startDate ? startDate : null}
              defaultEndDate={endDate ? endDate : null}
              InputProps={{
                sx: {
                  width: "100%",
                  height: "44px",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    component="div"
                    style={{ paddingLeft: "-14px" }}
                    disablePointerEvents
                  >
                    <CalendarIcon
                      fill={
                        dateErrors
                          ? colors.validationErrorRed
                          : colors.iconColor
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {!selectedLicense && (
            <Box sx={{ marginTop: "16px" }}>
              <BasicSelectWithCheckMark
                menuItems={tenantOptions}
                form={form}
                formItemLabel={"tenantId"}
                label={intl.formatMessage({ id: "tenant" })}
                rules={inputRules}
                SelectDisplayProps={SelectDisplayProps}
                inputLabelSx={inputLabelSx}
                menuPaperSx={menuPaperSx}
              />
            </Box>
          )}
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "num_users" })}
              type={"number"}
              form={form}
              formItemLabel={"users"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              rules={inputRules}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "num_sites" })}
              type={"number"}
              form={form}
              formItemLabel={"sites"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              rules={inputRules}
            />
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <BasicTextField
              label={intl.formatMessage({ id: "num_devices" })}
              type={"number"}
              form={form}
              formItemLabel={"devices"}
              variant={"outlined"}
              size={"small"}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              rules={inputRules}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", paddingBottom: "24px" }}>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton onClick={handleClose} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ marginRight: "16px" }}>
          <BasicButton
            onClick={handleSave}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal }}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LicenseDialog;
