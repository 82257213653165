import { useState, useEffect } from "react";
import Router from "./Router";
import { useLocation } from "react-router-dom";
import { TenantIdProvider } from "@datwyler/shared-components";

const App = () => {
  const location = useLocation();
  const [tenantId, setTenantId] = useState(null);
  const [isMasqAsSubTenant, setIsMasqAsSubTenant] = useState(false);

  useEffect(() => {
    if (location?.state?.tenantId) setTenantId(location.state.tenantId);
    setIsMasqAsSubTenant(!!location?.state?.isMasqAsSubTenant);
  }, [location]);

  return (
    <TenantIdProvider tenantId={tenantId} isMasqAsSubTenant={isMasqAsSubTenant}>
      {tenantId && <Router />}
    </TenantIdProvider>
  );
};

export default App;
