import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import {
  BasicDataTable,
  BasicPagination,
  getRequiredDateFormat,
  DarkModeContext,
} from "@datwyler/shared-components";

const headers = [
  "license_number",
  "start_date",
  "end_date",
  "num_users",
  "num_sites",
  "num_devices",
  "tenant",
  "curr_user_usage",
  "curr_site_usage",
  "curr_device_usage",
  "status",
];

const columnConfigs = {
  checkbox: {
    colspan: 0.5,
    isCentralized: false,
  },
  license_number: {
    colspan: 1.75,
    isCentralized: false,
  },
  start_date: {
    colspan: 1,
    isCentralized: false,
  },
  end_date: {
    colspan: 1,
    isCentralized: false,
  },
  num_users: {
    colspan: 0.75,
    isCentralized: false,
  },
  num_sites: {
    colspan: 0.75,
    isCentralized: false,
  },
  num_devices: {
    colspan: 0.75,
    isCentralized: false,
  },
  tenant: {
    colspan: 1.75,
    isCentralized: false,
  },
  curr_user_usage: {
    colspan: 0.75,
    isCentralized: false,
  },
  curr_site_usage: {
    colspan: 0.75,
    isCentralized: false,
  },
  curr_device_usage: {
    colspan: 0.75,
    isCentralized: false,
  },
  status: {
    colspan: 1,
    isCentralized: true,
  },
  actions: {
    colspan: 0.5,
    isCentralized: true,
  },
};

const LicenseTable = (props) => {
  const {
    licenseData,
    setSelectedLicense,
    setIsLicenseDialogOpen,
    setIsDeleteLicenseDialogOpen,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
  } = props;
  const intl = useIntl();
  const [tableData, setTableData] = useState([[]]);

  const { colors }: any = useContext(DarkModeContext);

  useEffect(() => {
    if (licenseData) {
      setTableData(getData());
    }
  }, [licenseData]);

  useEffect(() => {
    setTableData(getData());
  }, [page]);

  useEffect(() => {
    setTableData(getData());
  }, [rowsPerPage]);

  const getMoreButtonMenu = (license) => {
    return [
      {
        label: intl.formatMessage({ id: "update_license" }),
        value: null,
        onClick: () => handleOnClickUpdate(license),
      },
      {
        label: intl.formatMessage({ id: "delete_license" }),
        value: null,
        onClick: () => handleOnClickDelete(license),
        menuItemFontStyle: {
          color: colors.red500,
        },
        menuItemHoverStyle: {
          color: colors.red500,
        },
      },
    ];
  };

  const handleOnClickUpdate = (license) => {
    setSelectedLicense(license);
    setIsLicenseDialogOpen(true);
  };

  const handleOnClickDelete = (license) => {
    setSelectedLicense(license);
    setIsDeleteLicenseDialogOpen(true);
  };

  const getData = () => {
    const data = [];

    if (licenseData) {
      const temp = JSON.parse(JSON.stringify(licenseData));

      temp.forEach((license) => {
        data.push({
          isChecked: false,
          license_number: license.id,
          start_date: getRequiredDateFormat(license.startDate, "DD.MM.YYYY"),
          end_date: getRequiredDateFormat(license.endDate, "DD.MM.YYYY"),
          num_users: license.users?.total?.toString() || "0",
          num_sites: license.sites?.total?.toString() || "0",
          num_devices: license.devices?.total?.toString() || "0",
          tenant: license.tenant?.name,
          curr_user_usage: license.users?.current?.toString() || "0",
          curr_site_usage: license.sites?.current?.toString() || "0",
          curr_device_usage: license.devices?.current?.toString() || "0",
          status: intl.formatMessage({ id: license.status?.toLowerCase() }),
          additionalInfo: license,
          moreButtonMenuItems: getMoreButtonMenu(license),
        });
      });
    }

    return data;
  };

  const handleOnCheck = (additionalInfo) => {
    const temp: any = [...tableData];
    temp.map((row) => {
      if (row.additionalInfo.id === additionalInfo.id) {
        row.isChecked = !row.isChecked;
      }
    });
    setTableData(temp);
  };

  const handleCheckAll = (e) => {
    const temp = [...tableData];
    temp.map((row: any) => {
      row.isChecked = e.target.checked;
    });

    setTableData(temp);
  };

  const handleClickRow = (additionalInfo) => {
    handleOnCheck(additionalInfo);
  };

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={tableData}
        handleCheckAll={handleCheckAll}
        handleOnCheck={handleOnCheck}
        handleClickRow={handleClickRow}
        columnConfigs={columnConfigs}
      />
      <BasicPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalRows={totalRows}
      />
    </Box>
  );
};

export default LicenseTable;
